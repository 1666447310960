import React from 'react'
import PropTypes from 'prop-types'

import theme from '../../config/theme'

import Container from '../parts/Container'
import Columns from '../parts/Columns'
import Column from '../parts/Column'
import Seo from '../parts/Seo'
import HardFacts from '../parts/HardFacts'

import { Header, TitleWrapper, SvgOverlay } from './styled'

const Page = ({
  title,
  description,
  backgroundColor,
  titleNode,
  leftColumn,
  pullUp,
  svg,
  children,
}) => (
  <>
    <Seo title={title} description={description} />
    <HardFacts />
    <Header {...{ pullUp, backgroundColor }}>
      {titleNode && (
        <Container>
          <TitleWrapper>
            <Columns>
              {leftColumn !== null ? (
                <>
                  <Column size={3} />
                  <Column size={9}>{titleNode}</Column>
                </>
              ) : (
                <>
                  <Column size={5}>{titleNode}</Column>
                  <Column size={7} />
                </>
              )}
            </Columns>
          </TitleWrapper>
        </Container>
      )}
      {svg && <SvgOverlay>{svg}</SvgOverlay>}
    </Header>

    {leftColumn !== null ? (
      <Container style={{ marginTop: '3.5rem' }}>
        <Columns>
          <Column size={3}>{leftColumn}</Column>
          <Column size={9}>{children}</Column>
        </Columns>
      </Container>
    ) : (
      children
    )}
  </>
)

Page.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  titleNode: PropTypes.node,
  leftColumn: PropTypes.node,
  pullUp: PropTypes.bool,
  svg: PropTypes.node,
  children: PropTypes.node.isRequired,
}

Page.defaultProps = {
  title: '',
  description: '',
  backgroundColor: theme.palette.transparent[0],
  titleNode: null,
  leftColumn: null,
  pullUp: false,
  svg: null,
}

export default Page
