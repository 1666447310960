import styled from 'styled-components'
import { theme } from 'styled-tools'

const ColumnsWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => justify};
  margin-bottom: ${({ gap }) => gap && '2rem'};

  ${theme('media.smUp')} {
    flex-direction: ${({ flipDesktop }) => (flipDesktop ? 'row-reverse' : 'row')};
    margin-bottom: ${({ gap }) => gap && '4rem'};
  }
`

export default ColumnsWrapper
