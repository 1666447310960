import React from 'react'
import PropTypes from 'prop-types'
import ColumnsWrapper from './styled'

const Columns = ({
  flipDesktop, gap, justify, children,
}) => (
  <ColumnsWrapper {...{ flipDesktop, gap, justify }}>{children}</ColumnsWrapper>
)

Columns.displayName = 'Columns'

Columns.propTypes = {
  children: PropTypes.node.isRequired,
  flipDesktop: PropTypes.bool,
  gap: PropTypes.bool,
  justify: PropTypes.string,
}

Columns.defaultProps = {
  flipDesktop: false,
  gap: false,
  justify: 'space-between',
}
export default Columns
