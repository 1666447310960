import React from 'react'
import PropTypes from 'prop-types'
import Col from './styled'

const Column = ({ size, align, children }) => <Col {...{ size, align }}>{children}</Col>

Column.displayName = 'Column'

Column.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number,
  align: PropTypes.string,
}

Column.defaultProps = {
  children: null,
  size: 6,
  align: null,
}

export default Column
