import styled from 'styled-components'
import { theme } from 'styled-tools'

export const ColumnViewMediaThreshold = 'media.smDown'

const Col = styled.div`
  z-index: 100;
  align-self: ${({ align }) => align};
  width: 100%;

  ${theme('media.smUp')} {
    width: ${({ size }) => {
    if (size > 0 && size <= 12) {
      return `calc((100% * ${size}/12) - 1.75rem)`
    }
    return 'calc(50% - 1.75rem)'
  }};
  }
`

export default Col
