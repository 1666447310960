import * as React from 'react'
import styled from 'styled-components'

import theme from '../config/theme'
import Page from '../components/layouts/Page'
import Container from '../components/parts/Container'
import Heading from '../components/parts/Heading'
import Paragraph from '../components/parts/Paragraph'

const ContainerStyled = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  font-family: ${theme.fonts.libreBaskerville};
`

const FancyText = styled.p`
  color: ${theme.palette.sand[0]};
  font-size: 12.5rem;
  font-family: ${theme.fonts.libreBaskerville};
  transform: rotate(-6deg) translateY(1.5rem);
  ${theme.media.smDown} {
    font-size: 6.25rem;
  }
`

const HeadingStyled = styled(Heading)`
  z-index: ${theme.zIndex.base};
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  ${theme.media.smDown} {
    margin-bottom: 4rem;
    font-size: 1.5rem;
  }
`

const ParagraphStyled = styled(Paragraph)`
  max-width: 30rem;
  text-align: center;
  & > * {
    display: block;
  }
  & :last-child {
    margin-top: 2rem;
    font-weight: bold;
  }
`

const Success = () => (
  <Page title="Erfolg" description="">
    <ContainerStyled>
      <FancyText>Yeah!</FancyText>
      <HeadingStyled level={1}> Du bist Teil einer Bewegung!</HeadingStyled>
      <ParagraphStyled>
        <span>Toll, dass du dich uns anschließt!</span>
        <span>Du wirst per E-Mail informiert, sobald dein Produkt auf dem Weg zu dir ist.</span>
      </ParagraphStyled>
    </ContainerStyled>
  </Page>
)

export default Success
